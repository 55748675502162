<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refListTable"
      :items="dataList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nenhum registro encontrado"
      :sort-desc.sync="isSortDirDesc"
      striped
    >

      <template #thead-top>
        <b-tr>
          <b-th colspan="12">
            {{ totalCount }} Operações abertas
          </b-th>
        </b-tr>
      </template>

      <template #cell(paridade)="data">
        <div class="text-nowrap">
          <b-avatar
            size="sm"
            :src="getIcon(data.item.moedaTrade)"
          />
          <span class="mr-1">
            {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
          </span>
        </div>
      </template>

      <template #cell(preco)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          {{ tickers[data.item.moedaParidade].price }}
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(status)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          <div class="text-nowrap">
            <span
              :class="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.valorCompra)? 'text-success' : 'text-danger'"
            >
              {{ percentDiferenca(data.item.valorCompra, tickers[data.item.moedaParidade].price) }}%
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(dataCriacao)="data">
        {{ formatDateTimeSemAnoBR(data.value) }}
      </template>

      <template #cell(alvo1)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <b-badge variant="light-success">
            {{ getPercentDiff(data.item.valorCompra, data.value) }}%
          </b-badge>
          <feather-icon
            v-if="data.item.dataAlvo1!=null"
            class="text-success mr-1"
            icon="CheckIcon"
          />
        </template>
      </template>

      <template #cell(alvo2)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <div>
            <b-badge variant="light-success">
              {{ getPercentDiff(data.item.valorCompra, data.value) }}%
            </b-badge>
            <feather-icon
              v-if="data.item.dataAlvo2!=null"
              class="text-success mr-1"
              icon="CheckIcon"
            />
          </div>
        </template>
      </template>

      <template #cell(stopLoss)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <b-badge variant="light-danger">
            {{ getPercentDiff(data.item.valorCompra, data.value) }}%
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(acao)="data">
        <b-dropdown
          v-if="data.item.ordemTipo!='MARKET'"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            :disabled="!data.item.ordemCompraAbertaQtd==0 || lockFunctionTime(data.item.dataCriacao)"
            @click.stop="confirmarStopGain(data.item.id)"
          >
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">Stop gain</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-alterar-stop
            :disabled="!(data.item.ordemCompraAbertaQtd==0 && data.item.ordemVendaAbertaQtd>0) || lockFunctionTime(data.item.dataCriacao)"
            @click="dataModal = data.item"
          >
            <feather-icon :icon="data.item.stopLoss ? 'EditIcon' : 'PlusIcon'" />
            Stoploss
          </b-dropdown-item>

          <b-dropdown-item
            :disabled="lockFunctionTime(data.item.dataCriacao)"
            @click.stop="confirmarEncerrar(data.item.id)"
          >
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">Encerrar</span>
          </b-dropdown-item>

        </b-dropdown>

      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ paginationMeta.from }} a {{ paginationMeta.to }} de {{ paginationMeta.of }} registros</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-alterar-stop"
      ref="modal-alterar-stop"
      cancel-variant="outline-secondary"
      :ok-title="dataModal && dataModal.stopLoss ? 'Alterar' : 'Adicionar'"
      cancel-title="Sair"
      centered
      :title="dataModal && dataModal.stopLoss ? 'Alterar stop' : 'Adicionar stop'"
      @show="resetModalStop"
      @hidden="resetModalStop"
      @ok="gerenciarStopHandler"
    >
      <b-form
        v-if="dataModal"
        ref="formstopnovo"
        @submit.stop.prevent="gerenciarStop"
      >
        <b-form-group>
          <label for="preco-atual">
            Preço atual
            <b-badge
              :variant="parseFloat(tickers[dataModal.moedaParidade].price) >= parseFloat(dataModal.valorCompra)? 'light-success' : 'light-danger'"
            >
              {{ percentDiferenca(dataModal.valorCompra, tickers[dataModal.moedaParidade].price) }}%
            </b-badge>
          </label>
          <b-form-input
            id="preco-atual"
            type="text"
            disabled
            :value="tickers[dataModal.moedaParidade].price"
          />
        </b-form-group>
        <b-form-group v-if="dataModal.stopLoss">
          <label for="stop-atual">
            Stop atual
            <b-badge variant="light-danger">
              {{ getPercentDiff(dataModal.valorCompra, dataModal.stopLoss) }}%
            </b-badge>
          </label>
          <b-form-input
            id="stop-atual"
            type="text"
            disabled
            :value="dataModal.stopLoss"
          />
        </b-form-group>
        <b-form-group
          :state="novoStopState"
          invalid-feedback="Campo Stop novo obrigatório"
        >
          <label for="stop-novo">
            Stop novo
            <template v-if="novoStop">
              <b-badge variant="light-danger">
                {{ getPercentDiff(dataModal.valorCompra, novoStop) }}%
              </b-badge>
            </template>
          </label>
          <b-form-input
            id="alvo-novo"
            v-model="novoStop"
            :state="novoStopState"
            required
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BTable, BAvatar, BBadge, BPagination, BRow, BCol, BTr, BTh, BSpinner, BDropdown, BDropdownItem, BModal, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca } from '@/utils/filter'
import operacaoAbertaStoreModule from './operacaoAbertaStoreModule'

const OPERACAO_ABERTA_AGENTE_SPOT_STORE_MODULE_NAME = 'operacao-aberta-agente-spot'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BTr,
    BTh,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'paridade', label: 'Par' },
        { key: 'ordemTipoCompra', label: 'Tipo compra' },
        { key: 'valorCompra', label: 'Compra' },
        { key: 'preco', label: 'Preço' },
        { key: 'status', label: 'Status' },
        { key: 'alvo1', label: 'Alvo 1' },
        { key: 'alvo2', label: 'Alvo 2' },
        { key: 'stopLoss', label: 'Stop' },
        { key: 'ordemCompraAbertaQtd', label: 'Ordens compra' },
        { key: 'ordemVendaAbertaQtd', label: 'Ordens venda' },
        { key: 'dataCriacao', label: 'Envio' },
        { key: 'agentePerfilTrade', label: 'Perfil' },
        { key: 'agente', label: 'Agente' },
        { key: 'acao', label: 'Ações' },
      ],
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
      dataModal: null,
      novoStop: null,
      novoStopState: null,
    }
  },

  computed: {

    tickers() {
      return this.$store.state.binanceSpot.tickers
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(OPERACAO_ABERTA_AGENTE_SPOT_STORE_MODULE_NAME)) store.registerModule(OPERACAO_ABERTA_AGENTE_SPOT_STORE_MODULE_NAME, operacaoAbertaStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    clearInterval(this.polling)
    if (store.hasModule(OPERACAO_ABERTA_AGENTE_SPOT_STORE_MODULE_NAME)) store.unregisterModule(OPERACAO_ABERTA_AGENTE_SPOT_STORE_MODULE_NAME)
  },

  methods: {
    lockFunctionTime(dataCriacao) {
      const dataUnlock = new Date(dataCriacao)
      dataUnlock.setMinutes(dataUnlock.getMinutes() + 1)
      return new Date() < dataUnlock
    },

    //  stop novo
    checkFormValidityStop() {
      const valid = this.$refs.formstopnovo.checkValidity()
      this.novoStopState = valid
      return valid
    },

    resetModalStop() {
      this.novoStop = null
      this.novoStopState = null
    },

    gerenciarStopHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarStopSubmit()
    },

    gerenciarStopSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidityStop()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-alterar-stop'].toggle('#toggle-btn')
      })

      this.gerenciarStop()
    },

    gerenciarStop() {
      this.$store.dispatch('operacao-aberta-agente-spot/gerenciarStop', { id: this.dataModal.id, novoStop: this.novoStop })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Stop alterado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // vender agora
    confirmarStopGain(id) {
      this.$swal({
        title: 'Tem certeza que deseja acionar o Stop Gain?',
        text: 'Todos os seguidores desta operação teram suas ordens executadas a mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.stopGain(id)
        }
      })
    },

    confirmarEncerrar(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar o Sinal?',
        text: 'Todos os seguidores desta operação teram suas ordens finalizadas',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrar(id)
        }
      })
    },

    encerrar(id) {
      this.$store.dispatch('operacao-aberta-agente-spot/encerrar', { id })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Encerramento acionado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    stopGain(id) {
      this.$store.dispatch('operacao-aberta-agente-spot/stopGain', { id })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Stop gain acionado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    fetchList() {
      store
        .dispatch('operacao-aberta-agente-spot/getSinais', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          statusSinal: 0,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    pollfetchList() {
      this.fetchList()
      const self = this
      this.polling = setInterval(() => {
        self.fetchList()
      }, 5000)
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
