import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/spot/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSinais(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('sinal/agente', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    stopGain(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('sinal/stop-gain', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    encerrar(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('sinal/encerrar', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    gerenciarStop(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('sinal/alterar-stop', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
